export default [
  {
    title: 'Home',
    route: {name:'home'},
    icon: 'HomeIcon',
    privilegeId: 1
  },
  {
    title: 'Setting',
    icon: 'SettingsIcon',
    privilegeId: 2,
    children: [
      {
        title: 'Regional',
        route: { name: 'regionals' },
        privilegeId: 3
      },
      {
        title: 'Cabang',
        route: { name: 'companies' },
        privilegeId: 7
      },
      {
        title: 'Pengguna',
        route: { name: 'users' },
        privilegeId: 12
      },
      {
        title: 'Master Operasional',
        privilegeId: 26,
        children: [
          {
            title: 'Bank',
            route: { name: 'banks' },
            privilegeId: 27
          },
          {
            title: 'Pajak',
            route: { name: 'taxes' },
            privilegeId: 31
          },
          {
            title: 'Pemasok',
            route: { name: 'suppliers' },
            privilegeId: 36
          },
          {
            title: 'Mitra Pembayaran',
            route: { name: 'partners' },
            privilegeId: 40
          },
          {
            title: 'Jenis Biaya',
            route: { name: 'expenses' },
            privilegeId: 48
          }
        ]
      },
      {
        title: 'Master Inventori',
        privilegeId: 52,
        children: [
          {
            title: 'Satuan',
            route: { name: 'units' },
            privilegeId: 53
          },
          {
            title: 'Kategori Item',
            route: { name: 'itemcategories' },
            privilegeId: 57
          },
          {
            title: 'Item',
            route: { name: 'items' },
            privilegeId: 61
          },
          /*
          {
            title: 'Paket Item',
            route: { name: 'itempackages' },
            privilegeId: 65
          },
          */
          {
            title: 'Pajak Item',
            route: { name: 'itemtaxes' },
            privilegeId: 69
          }
        ]
      }
    ]
  },
  {
    title: 'Inventori',
    icon: 'PackageIcon',
    privilegeId: 73,
    tagName:'inventori',
    children: [
      // {
      //   title: 'Persediaan Awal',
      //   privilegeId: 74,
      //   route: {name:'openingbalances'}
      // },
      {
        title: 'Stok Masuk',
        privilegeId: 79,
        tagName:'stok_masuk',
        children: [
          {
            title: 'Permintaan',
            privilegeId: 80,
            tagName:'prm_stok_masuk',
            route: { name: 'stockins' }
          },
          {
            title: 'Pembelian',
            privilegeId: 86,
            tagName:'pembelian',
            route: { name: 'purchases' }
          },
          {
            title: 'Penerimaan',
            privilegeId: 91,
            tagName:'penerimaan',
            route: { name: 'receipts' }
          }
        ]
      },
      {
        title: 'Stok Keluar',
        privilegeId: 96,
        tagName:'stok_keluar',
        children: [
          {
            title: 'Transfer Keluar',
            privilegeId: 102,
            tagName:'transfer_keluar',
            route: { name: 'transferouts' }
          },
          {
            title: 'Stok Keluar',
            privilegeId: 97,
            tagName:'stok_keluar_p',
            route: { name: 'stockouts' }
          },
        ]
      },
      {
        title: 'Stok Opname',
        privilegeId: 108,
        route: { name: 'stockopnames' }
      }
    ]
  },
  {
    title: 'Operasional',
    icon: 'CpuIcon',
    privilegeId: 111,
    tagName:'ops',
    children: [
      {
        title: 'Ops. Masuk',
        privilegeId: 112,
        tagName:'ops_masuk',
        route: {name:'operationalins'}
      },
      {
        title: 'Ops. Keluar',
        privilegeId: 118,
        tagName:'ops_keluar',
        route: {name:'operationalouts'}
      }
    ]
  },
  {
    title: 'Laporan',
    icon: 'FileIcon',
    privilegeId: 124,
    children: [
      {
        title: 'Performa',
        privilegeId: 125,
        route: {name:'performreports'}
      },
      {
        title: 'Laba Rugi',
        privilegeId: 126,
        route: {name:'incomestatements'}
      },
      {
        title: 'Kartu Stok',
        privilegeId: 127,
        route: {name:'stockcards'}
      },
      {
        title: 'Operasional',
        privilegeId: 128,
        route: {name:'operationalreports'}
      },
      {
        title: 'Nilai Stok',
        privilegeId: 129,
        route: {name:'invbalancereports'}
      },
      {
        title: 'Posisi Kas',
        privilegeId: 130,
        route: {name:'cashpositionreports'}
      }
    ]
  }
]
