<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb class="breadcrumb-slash">
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                <small class="font-weight-bold">
                  <span v-if="!item.active">{{ item.text }}</span>
                  <a v-else @click="refresh">{{ item.text }}</a>
                </small>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->

    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
        size="sm"
      >

        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
          >
            <!-- <feather-icon icon="PlusIcon" /> -->
            Akses Cepat
          </b-button>
        </template>

        <b-dropdown-item :to="{ name: 'operationalins-add',query:{quickAction: true} }" :active="routeInclude('operationalins')||routeInclude('stockouts')||routeInclude('stockouts')">
          <span class="align-middle ml-50">Laporan Harian</span> <br>
          <b-badge variant="warning">{{currentPosition}}</b-badge>
        </b-dropdown-item>

        <!-- <b-dropdown-item :to="{ name: 'operationalouts-add',query:{quickAction: true} }" :active="routeInclude('operationalouts')">
          <span class="align-middle ml-50">2. Operasional Out</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'stockouts-add',query:{quickAction: true} }" :active="routeInclude('stockouts')">
          <span class="align-middle ml-50">3. Stok Keluar</span>
        </b-dropdown-item> -->

      </b-dropdown>
    </b-col>

  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BButton, BDropdownItem, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import _ from 'lodash'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BButton,
    BDropdownItem,
    BBadge
  },
  computed:{
    currentPosition(){
      const cRoute = this.$route
      if(cRoute.query.quickAction){
        return cRoute.meta.quickName
      } else {
        return ''
      }
    }
  },
  methods:{
    ...mapActions({
      refresh: 'app/refreshContentKey'
    }),
    routeInclude(routename){
      const cRoute = this.$route
      if(cRoute.query.quickAction){
        return _.includes(cRoute.name, routename)
      } else {
        return false
      }
    }
  }
}
</script>
